import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import BotaoSimular from "@/components/BotaoSimular";
import Grid from "@mui/material/Grid2";
import { Container, Link, Typography } from "@mui/material";
import "./pix-por-aproximacao.scss";
import { abreSimulador } from "@/services/utils";

export default function BlogPixPorAproximacao() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_PIX_POR_APROXIMACAO",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          size={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              size={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog_pix_por_aproximacao.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid size={12}>
              <Typography
                variant="h1"
                sx={{ mt: 2, p: 1, textAlign: "center" }}>
                Sabe o que é melhor do que Pix por aproximação? O Pix parcelado!{" "}
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 22, fontStyle: "italic" }}>
                O Banco Central acaba de liberar o Pix por aproximação, uma
                novidade que permite pagar contas apenas encostando o celular na
                maquininha, sem precisar abrir o aplicativo do banco.
              </Typography>
            </Grid>

            <Grid container spacing={2} sx={{ mx: 1, mt: 2 }}>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  Como funciona o Pix por aproximação?
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  <ol>
                    <li>
                      <strong>Tecnologia NFC:</strong> Basta aproximar o celular
                      da maquininha, como já acontece com cartões de crédito e
                      débito.
                    </li>
                    <li>
                      <strong>Facilidade e rapidez:</strong> O pagamento é feito
                      na hora, sem precisar digitar chave ou abrir apps.
                    </li>
                    <li>
                      <strong>Disponibilidade:</strong> Inicialmente, a
                      funcionalidade está disponível para Android via Google
                      Pay.
                    </li>
                    <li>
                      <strong>Segurança:</strong> A autenticação pode ser feita
                      via biometria, reconhecimento facial ou senha.
                    </li>
                  </ol>
                </Typography>
                <Typography sx={{ fontSize: 20, mt: 2 }}>
                  Essa tecnologia ainda está em implementação em alguns bancos e
                  estabelecimentos, mas tem grande potencial para tornar os
                  pagamentos Pix ainda mais práticos.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  Mas e se o saldo não for suficiente?
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Aí entra a <span className="quita">Quita Boletos</span> com o
                  Pix parcelado no cartão de crédito!
                </Typography>
                <Typography sx={{ fontSize: 20, mt: 2 }}>
                  Imagine poder pagar suas contas em até 12x no cartão de
                  crédito, sem comprometer seu saldo imediato. Com a{" "}
                  <span className="quita">Quita Boletos</span>, você pode:
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  <ul className="list-benefits">
                    <li>✅ Parcelar boletos e Pix no cartão de crédito</li>
                    <li>✅ Evitar juros altíssimos do cheque especial</li>
                    <li>✅ Ganhar mais tempo para organizar as finanças</li>
                    <li>✅ Pagar contas sem comprometer o saldo do mês</li>
                  </ul>
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  Como funciona o Pix parcelado?
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  <ol>
                    <li>
                      Clique{" "}
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() => abreSimulador()}>
                        AQUI{" "}
                      </Link>{" "}
                      ou em <b>SIMULAR</b> e
                      <strong>
                        {" "}
                        escolha o Pix ou boleto que deseja parcelar.
                      </strong>
                    </li>
                    <li>
                      Escolha o <strong>número de parcelas</strong> que cabe no
                      seu bolso.
                    </li>
                    <li>
                      Finalize o pagamento com seu{" "}
                      <strong>cartão de crédito</strong> e pronto! Conta quitada
                      sem sufoco.
                    </li>
                  </ol>
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  Pix por aproximação ou Pix parcelado?{" "}
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  O <strong>Pix por aproximação</strong> é incrível para
                  pagamentos rápidos e práticos. Mas quando a conta aperta, o{" "}
                  <strong>Pix parcelado</strong> no cartão de crédito da{" "}
                  <span className="quita">Quita Boletos</span> é a solução ideal
                  para manter suas finanças sob controle.
                </Typography>
                <Typography sx={{ fontSize: 20, mt: 2 }}>
                  Quer experimentar? Clique no botão abaixo e parcele seu Pix
                  agora:
                </Typography>
              </Grid>

              <Grid size={12} align="center">
                <BotaoSimular>CLIQUE AQUI E PARCELE SEU PIX</BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

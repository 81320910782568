import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BotaoSimular from "@/components/BotaoSimular";

export default function TendenciasDoEndividamentoNoBrasil() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_TENDENCIAS_DO_ENDIVIDAMENTO_NO_BRASIL",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          size={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              size={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog_tendencias_do_endividamento_no_brasil.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid size={12}>
              <Typography variant="h1" sx={{ mt: 2, p: 1 }}>
                Tendências do endividamento no Brasil: Como a{" "}
                <span className="quita">Quita Boletos</span> pode ajudar na
                organização financeira
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography
                sx={{ fontSize: 22, fontStyle: "italic" }}
                gutterBottom>
                Nos últimos anos, o endividamento das famílias brasileiras tem
                sido uma preocupação constante. De acordo com dados da
                Confederação Nacional do Comércio de Bens, Serviços e Turismo
                (CNC), em novembro de 2024, 77% das famílias possuíam algum tipo
                de dívida, um aumento em relação ao mesmo período do ano
                anterior, que registrou 76,6%.
              </Typography>
            </Grid>

            <Grid container spacing={2} sx={{ mx: 1, mt: 2 }}>
              <Grid size={12}>
                <Typography sx={{ fontSize: 20, mb: 2 }}>
                  Esse crescimento está associado ao uso sazonal do crédito para
                  compras de fim de ano, aliado a uma gestão mais cautelosa do
                  orçamento familiar. Entretanto, a inadimplência também
                  apresentou elevação, atingindo 29,4% das famílias com dívidas
                  em atraso – o maior índice desde outubro de 2023. Além disso,{" "}
                  <strong>
                    12,9% dos consumidores declararam não ter condições de
                    quitar seus débitos.
                  </strong>
                </Typography>
              </Grid>

              <Grid size={12}>
                <Typography sx={{ fontSize: 20 }}>
                  Diante desse cenário, é essencial que as famílias busquem
                  alternativas para organizar suas finanças e evitar a
                  inadimplência. A <span className="quita">Quita Boletos</span>{" "}
                  surge como uma solução eficaz, oferecendo serviços que
                  auxiliam na gestão e quitação de dívidas, permitindo que os
                  consumidores consolidem seus débitos e negociem condições de
                  pagamento mais favoráveis.
                </Typography>
              </Grid>

              <Grid size={12}>
                <Typography sx={{ fontSize: 20 }}>
                  Com a <span className="quita">Quita Boletos</span>, você pode
                  parcelar seus boletos e Pix em uma única plataforma,
                  garantindo mais controle e evitando atrasos que resultam em
                  juros e multas. Além disso, as opções de parcelamento são
                  flexíveis e adaptáveis ao seu orçamento, ajudando na
                  organização das finanças e promovendo uma recuperação
                  financeira sustentável.
                </Typography>
              </Grid>

              <Grid size={12}>
                <Typography sx={{ fontSize: 20, mb: 1 }}>
                  Em tempos de instabilidade econômica, contar com ferramentas
                  que promovam a educação financeira e a organização das
                  finanças pessoais é fundamental. A{" "}
                  <span className="quita">Quita Boletos</span> não apenas
                  auxilia na quitação de dívidas, mas também incentiva hábitos
                  financeiros saudáveis, preparando as famílias para um futuro
                  mais seguro e equilibrado.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 20, mb: 1 }}>
                  Boletos e Pix pesando no orçamento? Parcela com a{" "}
                  <span className="quita">Quita Boletos</span> e alivie suas
                  finanças!
                </Typography>
              </Grid>
              <Grid size={12} align="center">
                <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

import SiteLayout from "../layout/SiteLayout";
import Blog from "../pages/site/Blog";
import BeneficiosDoParcelamento from "../pages/site/Blog/Posts/benefícios-do-parcelamento-de-contas";
import EndividamentoImpactaPsicologico from "../pages/site/Blog/Posts/endividamento-impacto-psicologico";
import ParcelarMeusBoletos from "../pages/site/Blog/Posts/parcelamento-boletos";
import FgtsAdiantamento from "../pages/site/Blog/Posts/fgts-fazer-adiantamento";
import SegredoBoletos from "../pages/site/Blog/Posts/segredo-sobre-parcelar-boletos";
import CincoBeneficiosParcelerBoletos from "../pages/site/Blog/Posts/5-beneficios-de-parcelar-boletos";
import MenosDividasMaisEsperancaFinanceira from "../pages/site/Blog/Posts/brasil-2023-menos-dividas-mais-esperanca-financeira";
import LiberdadeDaInadimplencia from "../pages/site/Blog/Posts/liberte-se-da-inadimplencia-e-conquiste-a-libertade-financeira";
import MasAfinalComoFunciona from "../pages/site/Blog/Posts/mas-afinal-como-funciona";
import GuiaParaManterAsFinancasEmOrdem from "../pages/site/Blog/Posts/guia-para-manter-as-financas-em-ordem";
import ParcelamentoDeContasEstrategiaInteligente from "../pages/site/Blog/Posts/parcelamento-de-contas";
import TudoQuePrecisaSaberSobreParcelamentoPix from "../pages/site/Blog/Posts/parcelamento-pix";
import ParcelarContasAtravesDoCartaoDeCredito from "../pages/site/Blog/Posts/parcelar-contas-atraves-cartao-de-credito";
import ComoPagarBoletoVencido from "../pages/site/Blog/Posts/como-pagar-boleto-vencido";
import EntendaComoParcelarIPTUeIPVANoCartao from "../pages/site/Blog/Posts/entenda-como-parcelar-iptu-e-ipva-no-cartao";
import ParcelamentoDePixNoCredito from "../pages/site/Blog/Posts/parcelamento-de-pix-no-credito";
import ParcelamentoDeContasTudoQuePrecisaSaber from "../pages/site/Blog/Posts/parcelamento-de-contas-tudo-que-precisa-saber";
import DicasParaManterAsContasEmDia from "../pages/site/Blog/Posts/5-dicas-para-manter-as-contas-em-dia";
import HabitosDePessoasBemSucedidas from "../pages/site/Blog/Posts/5-habitos-de-pessoas-bem-sucedidas";
import ComoManterSeusPagamentosEmDia from "../pages/site/Blog/Posts/organizacao-financeira-como-manter-seus-pagamentos-em-dia";
import ComoPossoOrganizarAsFinancasPessoais from "../pages/site/Blog/Posts/como-posso-organizar-as-financas-pessoais";
import LiberdadeDeFazerPixSemSaldoNaConta from "../pages/site/Blog/Posts/liberdade-de-fazer-pix-sem-saldo";
import NosTeConvidamosADesacelerar from "../pages/site/Blog/Posts/nos-te-convidamos-a-desacelerar";
import NossoFuturoComecaAgora from "../pages/site/Blog/Posts/nosso-futuro-comeca-agora";
import QuerSaberComoEvitarOEndividamento from "../pages/site/Blog/Posts/quer-saber-como-evitar-o-endividamento";
import DesvantagensUsoChequeEspecialEOutrasAlternativas from "../pages/site/Blog/Posts/desvantagens-uso-cheque-especial";
import NovasRegrasDoPix from "../pages/site/Blog/Posts/novas-regras-pix";
import PixComCartaoDeCreditoASolucaoIdeal from "../pages/site/Blog/Posts/pix-com-cartao-de-credito-a-solucao-ideal";
import BlackFridayComoEvitarSitesFraudulentosEGolpes from "../pages/site/Blog/Posts/black-friday-como-evitar-sites-fraudulentos-e-compras-golpe";
import DicasPraticasParaEvitarDividasEManterOEquilibrioFinanceiro from "../pages/site/Blog/Posts/dicas_praticas_para_evitar_dividas_e_manter_o_equilibrio_financeiro";
import ComoSairDoVermelhoEm2025 from "../pages/site/Blog/Posts/como-sair-do-vermelho-em-2025";
import NovasRegrasParaPix from "../pages/site/Blog/Posts/novas-regras-para-pix";
import SelicPorqueAfetaSuasContas from "../pages/site/Blog/Posts/taxa_selic_por_que_afeta_suas_contas";
import EducacaoFinanceiraOCaminhoParaUmaVidaComQualidade from "../pages/site/Blog/Posts/educacao-financeira-o-caminho-para-uma-vida-com-mais-qualidade";
import AltaDaSelicEndividamentoNoBrasil from "../pages/site/Blog/Posts/alta-da-selic-e-o-endividamento-no-brasil";
import PixCartaoDeCreditoSaibaComoDividirSeusPagamentos from "../pages/site/Blog/Posts/pix-cartao-de-credito-saiba-como-dividir-seus-pagamentos";
import PagarAsContasSemComprometerTodoOrcamento from "../pages/site/Blog/Posts/pagar-as-contas-sem-comprometer-todo-orcamento";
import BlogSolucoesFinanceirasParaNegativados from "../pages/site/Blog/Posts/solucoes-financeiras-para-negativados";
import BlogPixPorAproximacao from "../pages/site/Blog/Posts/pix-por-aproximacao";
import TendenciasDoEndividamentoNoBrasil from "../pages/site/Blog/Posts/tendencias_do_endividamento_no_brasil";

const BlogRoutes = {
  path: "/blog",
  element: <SiteLayout />,
  children: [
    {
      path: "/blog",
      element: <Blog />,
    },
    {
      path: "como-parcelar-meus-boletos",
      element: <ParcelarMeusBoletos />,
    },
    {
      path: "endividamento-impacta-psicologico",
      element: <EndividamentoImpactaPsicologico />,
    },
    {
      path: "os-beneficios-do-parcelamento-de-contas",
      element: <BeneficiosDoParcelamento />,
    },
    {
      path: "fgts-fazer-adiantamento-ou-nao",
      element: <FgtsAdiantamento />,
    },
    {
      path: "segredos-que-ninguem-te-contou",
      element: <SegredoBoletos />,
    },
    {
      path: "cinco-beneficios-de-parcelar-boletos",
      element: <CincoBeneficiosParcelerBoletos />,
    },
    {
      path: "menos-dividas-mais-esperanca",
      element: <MenosDividasMaisEsperancaFinanceira />,
    },
    {
      path: "liberte-se-da-inadimplencia",
      element: <LiberdadeDaInadimplencia />,
    },
    {
      path: "como-funciona",
      element: <MasAfinalComoFunciona />,
    },
    {
      path: "guia-para-manter-as-financas-em-ordem",
      element: <GuiaParaManterAsFinancasEmOrdem />,
    },
    {
      path: "parcelamento-de-contas",
      element: <ParcelamentoDeContasEstrategiaInteligente />,
    },
    {
      path: "parcelamento-de-pix",
      element: <TudoQuePrecisaSaberSobreParcelamentoPix />,
    },
    {
      path: "parcelar-atraves-do-cartao-de-credito",
      element: <ParcelarContasAtravesDoCartaoDeCredito />,
    },
    {
      path: "como-pagar-boleto-vencido",
      element: <ComoPagarBoletoVencido />,
    },
    {
      path: "parcelar-iptu-e-ipva-no-cartao",
      element: <EntendaComoParcelarIPTUeIPVANoCartao />,
    },
    {
      path: "parcelamento-pix-credito",
      element: <ParcelamentoDePixNoCredito />,
    },
    {
      path: "tudo-que-precisa-saber-sobre-parcelamento-de-contas",
      element: <ParcelamentoDeContasTudoQuePrecisaSaber />,
    },
    {
      path: "dicas-para-manter-as-contas-em-dia",
      element: <DicasParaManterAsContasEmDia />,
    },
    {
      path: "habitos-de-pessoas-bem-sucedidas",
      element: <HabitosDePessoasBemSucedidas />,
    },
    {
      path: "como-manter-seus-pagamentos-em-dia",
      element: <ComoManterSeusPagamentosEmDia />,
    },
    {
      path: "como-organizar-as-finanacas-pessoais",
      element: <ComoPossoOrganizarAsFinancasPessoais />,
    },
    {
      path: "liberdade-para-realizar-pix-sem-saldo-na-conta",
      element: <LiberdadeDeFazerPixSemSaldoNaConta />,
    },
    {
      path: "nos-te-convidamos-a-desacelerar",
      element: <NosTeConvidamosADesacelerar />,
    },
    {
      path: "nosso-futuro-comeca-agora",
      element: <NossoFuturoComecaAgora />,
    },
    {
      path: "quer-saber-como-evitar-o-endividamento",
      element: <QuerSaberComoEvitarOEndividamento />,
    },
    {
      path: "desvantagens-uso-cheque-especial",
      element: <DesvantagensUsoChequeEspecialEOutrasAlternativas />,
    },
    {
      path: "novas-regras-pix",
      element: <NovasRegrasDoPix />,
    },
    {
      path: "pix_com_cartao_de_credito_a_solucao_ideal",
      element: <PixComCartaoDeCreditoASolucaoIdeal />,
    },
    {
      path: "black_friday_como_evitar_sites_fraudulentos_e_golpes",
      element: <BlackFridayComoEvitarSitesFraudulentosEGolpes />,
    },
    {
      path: "dicas_praticas_para_evitar_dividas_e_manter_o_equilibrio_financeiro",
      element: <DicasPraticasParaEvitarDividasEManterOEquilibrioFinanceiro />,
    },
    {
      path: "como_sair_do_vermelho_em_2025",
      element: <ComoSairDoVermelhoEm2025 />,
    },
    {
      path: "novas_regras_para_o_pix",
      element: <NovasRegrasParaPix />,
    },
    {
      path: "selic_por_que_afeta_suas_contas",
      element: <SelicPorqueAfetaSuasContas />,
    },
    {
      path: "educacao_financeira_o_caminho_para_uma_vida_com_mais_qualidade",
      element: <EducacaoFinanceiraOCaminhoParaUmaVidaComQualidade />,
    },
    {
      path: "alta_da_selic_e_o_endividamento_no_brasil",
      element: <AltaDaSelicEndividamentoNoBrasil />,
    },
    {
      path: "pix_cartao_de_credito_saiba_como_dividir_seus_pagamentos",
      element: <PixCartaoDeCreditoSaibaComoDividirSeusPagamentos />,
    },
    {
      path: "pagar_contas_sem_comprometer_todo_seu_orcamento_de_uma_vez",
      element: <PagarAsContasSemComprometerTodoOrcamento />,
    },
    {
      path: "solucoes_financeiras_para_negativados",
      element: <BlogSolucoesFinanceirasParaNegativados />,
    },
    {
      path: "pix_por_aproximacao",
      element: <BlogPixPorAproximacao />,
    },
    {
      path: "tendencias_do_endividamento_no_brasil",
      element: <TendenciasDoEndividamentoNoBrasil />,
    },
  ],
};

export default BlogRoutes;

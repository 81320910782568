import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import "./index.scss";

const TermosUso = () => {
  return (
    <>
      <Container className="sessao-site sessao-termos-uso">
        <Grid container justifyContent="center">
          <Grid item size={{ xs: 12, md: 8 }} align="center">
            <Typography variant="h3" className="titulo">
              Termos de Uso
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ m: { md: "auto" } }}
          maxWidth="md"
          justifyContent="center">
          <Grid item size={12}>
            <Typography className="subtitulo">Considerando que: </Typography>

            <Typography className="descricao">
              (i) A Quita Tecnologia LTDA, inscrita no CNPJ sob nº
              42.426.994/0001-52 (“Quita Tecnologia”), atua viabilizando o
              pagamento de boletos, com cartões de débito ou crédito, de forma
              parcelada ou à vista. (ii) A Quita Tecnologia disponibiliza para
              os consumidores interessados (“Usuários”) os serviços de pagamento
              de boletos, um portal digital que pode ser acessado por meio da
              URL www.quitaboletos.com.br (“Portal Digital”), por meio do qual o
              usuário possui acesso a todas as informações necessárias, bem como
              ao efetivo parcelamento dos débitos. A Quita Tecnologia
              disponibiliza o presente Termo de Uso e Outras Avenças (“Termo”),
              que constituirá, para todos os efeitos, um contrato celebrado
              entre a Quita Tecnologia e qualquer pessoa que atenda todos os
              requisitos do presente Termo e deseje contratar os Serviços, o
              qual deverá ser lido integralmente, antes de sua aceitação.
            </Typography>
            <Typography className="subtitulo">
              Aceitação do Termo de Uso{" "}
            </Typography>

            <Typography className="descricao">
              1.1. O presente Termo contém condições de contratação e uso do
              Portal Digital para o acesso aos Serviços prestados pela Quita
              Tecnologia.
            </Typography>
            <Typography className="descricao">
              1.2. A Quita Tecnologia, na qualidade de facilitadora, fornece ao
              Usuário, sujeito aos Termos de Uso abaixo, acesso ao Portal
              Digital para contratação de seus Serviços.
            </Typography>
            <Typography className="descricao">
              1.3. As informações contidas no Portal Digital poderão ser
              alteradas, modificadas, ajustadas ou extintas a qualquer momento,
              sem que haja a necessidade de informação prévia ao Usuário, sendo
              que essas alterações, modificações e/ou ajustes terão efeito
              somente após se tornarem públicos.
            </Typography>
            <Typography className="descricao">
              1.4. Ao realizar a contratação dos Serviços, o USUÁRIO declara que
              leu e concordou expressamente com a versão mais recente do Termo
              de Uso, fato este que o vinculará automaticamente às regras aqui
              contidas.
            </Typography>
            <Typography className="subtitulo">
              Descrição dos Serviços{" "}
            </Typography>

            <Typography className="descricao">
              2.1. A Quita Tecnologia, por meio do Portal Digital, disponibiliza
              o acesso a produtos e serviços para pagamento de boletos, por meio
              de cartão de débito ou crédito, de forma parcelada ou à vista
              (“Transação”).
            </Typography>
            <Typography className="descricao">
              2.2. Para obter acesso aos Serviços, o Usuário deverá acessar o
              Portal Digital, por meio do fornecimento correto das informações
              solicitadas.
            </Typography>
            <Typography className="descricao">
              2.3. O Usuário fica desde logo informado e concorda que os
              Serviços estarão disponíveis 24 (vinte e quatro) horas por dia,
              7(sete) dias por semana, salvo em casos de culpa de terceiros,
              caso fortuito ou força maior, e manutenções técnicas e/ ou
              operacionais que exijam o desligamento temporário do sistema ou
              impossibilitem o acesso.
            </Typography>
            <Typography className="descricao">
              2.4. Por meio do Portal Digital, o Usuário poderá realizar as
              seguintes operações: I – Cadastramento de dados pessoais; II –
              Contratação de um dos Serviços disponibilizados pela Quita
              Tecnologia.
            </Typography>
            <Typography className="subtitulo">Cadastro do Usuário</Typography>
            <Typography className="descricao">
              3.1. Para a utilização dos Serviços, o Usuário deverá efetuar
              preenchimento de seus dados previamente no Portal Digital, no qual
              deverá fornecer informações pessoais verdadeiras e atuais, dentre
              elas, o nome ou razão social, o CPF ou CNPJ, telefone e endereço
              de e – mail e no caso de pessoas jurídicas, os dados cadastrais de
              seus sócios ou representantes legais, responsabilizando-se pela
              veracidade das informações e pela sua atualização, quando
              necessário.
            </Typography>
            <Typography className="descricao">
              3.2. Só poderão se cadastrar no Portal Digital pessoas físicas ou
              jurídicas que tenham capacidade legal para contratar e que sejam
              residentes no Brasil. Portanto, não poderão se cadastrar e
              manter-se cadastrados os menores de 18 anos, as pessoas civilmente
              incapazes, pessoas sem CPF ou sem CNPJ, ou ainda, com CPF ou CNPJ
              baixado, cancelado, não informado, nulo, suspenso, cujo titular
              tenha falecido, ou cujo sócio e / ou representante indicado se
              enquadre em alguma dessas situações ou aqueles Usuários que tenham
              sido suspensos ou inabilitados no Portal Digital, temporária ou
              definitivamente.
            </Typography>
            <Typography className="descricao">
              3.3. As informações pessoais fornecidos pelo Usuário deverão ser
              completas, com informações exatas, precisas e verdadeiras, sendo o
              Usuário o único responsável, civil e criminalmente, pelos dados
              fornecidos, obrigando – se, ainda, a atualizá-los sempre que
              necessário e/ ou solicitado sob pena(i) de não utilização dos
              Serviços; (ii)suspensão dos Serviços; e / ou(iii)
              responsabilização nas esferas cível, administrativa e criminal na
              forma prevista em Lei.
            </Typography>
            <Typography className="descricao">
              3.4. Caso seja verificado que as informações fornecidas são falsas
              ou incompletas, Quita Tecnologia se dá o direito de não
              fornecimento dos serviços, até que a situação seja regularizada.
            </Typography>
            <Typography className="descricao">
              3.5. A Quita Tecnologia disponibiliza para os usuários, antes da
              contratação dos Serviços, a Política de Privacidade praticada pela
              empresa, a qual trata especificamente do fornecimento de dados
              pessoais do Usuário para cadastro e o seu tratamento.
            </Typography>
            <Typography className="descricao">
              3.6 O Usuário, ao contratar os Serviços, declara, também, que teve
              acesso integral e aceita os termos da Política de Privacidade da
              Quita Tecnologia.
            </Typography>
            <Typography className="subtitulo">Condições do Serviço </Typography>

            <Typography className="descricao">
              4.1. A Quita Tecnologia atua exclusivamente como empresa
              facilitadora, de modo a viabilizar o pagamento de débitos pelo
              Usuário, razão pela qual o parcelamento no cartão de crédito ou o
              pagamento à vista por meio de cartão de débito é de exclusiva
              responsabilidade do responsável pelo cartão utilizado.
            </Typography>
            <Typography className="descricao">
              4.2. Em caso de recurso deferido por uma das partes envolvidas
              (Usuários ou Órgão/Empresa Emissora do Boleto), após o pagamento
              do débito, haverá a restituição, por exclusiva responsabilidade do
              Órgão/Empresa Emissora do Boleto, apenas dos valores acertados
              entre essas partes (Usuários e Órgão/Empresa Emissora do Boleto),
              excluídas taxas administrativas relativas ao pagamento e / ou
              parcelamento via cartão.
            </Typography>
            <Typography className="descricao">
              4.3. Todas as taxas de juros envolvidas no parcelamento, inclusive
              CET quando houver, valor total do parcelamento e o valor de cada
              parcela serão informadas no Portal Digital durante a contratação
              dos Serviços. O Usuário deverá garantir que entendeu de forma
              clara as condições oferecidas pela Quita Tecnologia antes da
              contratação dos Serviços, devendo entrar em contato por meio de um
              dos canais disponibilizados em caso de dúvidas.
            </Typography>
            <Typography className="descricao">
              4.4. Ao contratar os Serviços por meio da concretização da
              Transação de pagamento, o Usuário concorda expressamente com todos
              os valores e forma de pagamento oferecidos pela Quita Tecnologia.
            </Typography>
            <Typography className="descricao">
              4.5. Em caso de contestação da Transação pela Instituição
              Financeira, de forma posterior ao parcelamento ou pagamento do
              débito, sem motivação comprovada, o Usuário poderá sofrer sanções
              legais e administrativas, além de indenizar a Quita Tecnologia por
              eventuais prejuízos sofridos, conforme cláusula 5.1 deste Termo.
            </Typography>
            <Typography className="descricao">
              4.6. A Quita Tecnologia reserva-se o direito de não processar as
              Transações que: (i) contenham dados incompletos, contraditórios ou
              inválidos; (ii) A Quita Tecnologia, a seu exclusivo critério,
              considere envolver algum risco; (ii) estejam em desacordo ao
              presente Termo; ou (iv) não sejam autorizadas pelo banco ou pela
              credenciadora ou pelo emissor de cartões de crédito ou débito.
            </Typography>
            <Typography className="descricao">
              4.7. A Quita Tecnologia poderá cancelar uma Transação sempre que
              verificar que a mesma: (i) foi invalidada pelo banco do Usuário;
              (ii) foi processada incorretamente pela Quita Tecnologia, (iii) o
              Usuário não tinha autorização para enviá-lo, seja por fraude ou
              qualquer outro motivo, (iv) decorre de qualquer operação em
              desacordo com este Termo, ou (v) caso de suspeita de fraude ou
              qualquer irregularidade.
            </Typography>
            <Typography className="subtitulo">Indenização</Typography>
            <Typography className="descricao">
              5.1. O Usuário concorda em indenizar a Quita Tecnologia de
              qualquer dano que esta venha a sofrer por culpa ou dolo do Usuário
              na utilização irregular ou ilegal dos Serviços e em razão do
              descumprimento do presente Termo de Uso e/ ou demais políticas da
              Quita Tecnologia; ou ainda pela violação de qualquer lei e direito
              de terceiros, responsabilizando – se por eventuais perdas e danos.
            </Typography>
            <Typography className="descricao">
              5.2.A Quita Tecnologia não poderá ser responsabilizada por
              qualquer falha ou não funcionamento dos serviços provenientes de
              terceiros ou decorrentes de caso fortuito ou força maior. 5.3.Em
              hipótese alguma a Quita Tecnologia poderá ser responsabilizada por
              qualquer erro originado na utilização ou não utilização do
              Serviço.
            </Typography>
            <Typography className="subtitulo">Prazo </Typography>

            <Typography className="descricao">
              6.1. O Portal Digital estará disponível para o Usuário por prazo
              indeterminado, sendo certo que a Quita Tecnologia poderá, à sua
              livre discrição, encerrar a prestação dos Serviços,
              independentemente de qualquer tipo de comunicação prévia ao
              Usuário.
            </Typography>
            <Typography className="descricao">
              6.2. Em caso de encerramento dos Serviços, a Quita Tecnologia
              permanecerá responsável por todos os direitos e obrigações
              decorrentes de Serviços já contratados e não encerrados.
            </Typography>
            <Typography className="subtitulo">Disposições Gerais</Typography>
            <Typography className="descricao">
              7.1. A Quita Tecnologia utilizará os dados e informações pessoais
              dos Usuários para prestar os Serviços, nas formas e casos
              estabelecidos na Política de Privacidade da Quita Tecnologia. No
              entanto, além das hipóteses previstas na Política de Privacidade,
              a Quita Tecnologia poderá coletar e revelar dados cadastrais de
              seus usuários para terceiros, incluindo, mas sem se limitar a,
              empresas integrantes do mesmo grupo econômico, parceiros
              comerciais, instituições financeiras, autoridades e pessoas
              físicas ou jurídicas(i) que alegarem ter sido lesadas por
              Usuários; (ii) com a finalidade de realizar estudos internos,
              (iii) que prestem serviços à Quita Tecnologia e/ ou com parceiros
              comerciais para fins de negócios da Quita Tecnologia, tais como,
              mas sem se limitar ao gerenciamento de risco, a detecção de atos
              ilícitos, o envio anúncios personalizados, a oferta conjunta de
              produtos ou serviços, a análise de dados estatísticos, dentre
              outros; (iv)que prestem serviços de valor agregado que se integrem
              dentro do Portal Digital ou mediante links a outros sites
              eletrônicos da Internet, para atender necessidades dos Usuários
              relacionadas aos Serviços; (iv) com o objetivo de propor
              iniciativas comerciais e promocionais da Quita Tecnologia. Para
              mais informações, consulte as Políticas de Privacidade da Quita
              Tecnologia, que passam a fazer parte integrante dos presente
              Termo.
            </Typography>
            <Typography className="descricao">
              7.2. O Usuário autoriza de forma irrevogável a Quita Tecnologia ou
              quem a Quita Tecnologia designe, a gravar todas as operações e /
              ou telefonemas do Usuário relativos aos serviços prestados pela
              Quita Tecnologia, e a utilizar essas gravações como meio
              probatório perante autoridades administrativas ou judiciais. Os
              registros emitidos pela Quita Tecnologia serão considerados prova
              suficiente do cumprimento das Transações, substituem a necessidade
              de qualquer recibo e são plenamente oponíveis ao Usuário.
            </Typography>
            <Typography className="descricao">
              7.3. A não exigência, por qualquer uma das partes, do cumprimento
              de qualquer cláusula ou condição estabelecida, será considerada
              mera tolerância, não implicando na sua novação, e tão pouco na
              abdicação do direito de exigi-la no futuro, não afetando a
              validade deste instrumento e quaisquer de suas condições.
            </Typography>
            <Typography className="descricao">
              7.4. Os regulamentos relativos a eventuais campanhas promocionais,
              propagandas de incentivo e outros programas que propiciem
              benefícios adicionais ao Usuário serão divulgados separadamente,
              sendo que, por mera liberalidade, alguns serviços poderão ser
              oferecidos gratuitamente ou com variações dos preços e condições
              regularmente ofertados a título promocional e por prazo
              determinado.
            </Typography>
            <Typography className="descricao">
              7.5. Na hipótese de qualquer uma das disposições deste Termo vir a
              ser considerada contrária à lei brasileira, por qualquer
              autoridade governamental ou decisão judicial, as demais
              disposições não afetadas continuarão em vigor e as partes deverão
              alterar este instrumento de forma a adequá-lo a tal lei ou à
              decisão judicial.
            </Typography>
            <Typography className="descricao">
              7.6. Este Termo obriga as partes e seus sucessores a qualquer
              título.
            </Typography>
            <Typography className="descricao">
              7.7. O Usuário reconhece que poderão ser veiculados anúncios e
              propagandas de terceiros e que, caso venha a adquirir qualquer
              produto ou serviço lá anunciado, caberá ao anunciante a
              responsabilidade por quaisquer produtos ou serviços adquiridos,
              bem como pelo conteúdo publicado.
            </Typography>
            <Typography className="descricao">
              7.8. O usuário autoriza a Quita Tecnologia a utilizar recursos,
              tais como, mas não se limitando, a & quot; cookies & quot;
              (pequenas mensagens comerciais instantâneas), a fim de oferecer um
              Serviço melhor e mais personalizado.
            </Typography>
            <Typography className="descricao">
              7.9. O Usuário declara, ainda, que cumpre e faz cumprir, bem como
              suas afiliadas, acionistas, conselheiros, diretores, funcionários,
              agentes e/ ou eventuais subcontratados(“Pessoas Relacionadas”),
              todas as leis, regras, regulamentos e normas aplicáveis, emitidos
              por qualquer jurisdição aplicável ao Usuário, que versam sobre
              atos de corrupção, suborno e atos lesivos contra a administração
              pública, incluindo, mas não se limitando a Lei nº 12.846 /
              13(“Leis Anticorrupção”); na medida em que(i) mantém políticas e
              procedimentos internos que assegurem integral cumprimento das Leis
              Anticorrupção; (ii)dá pleno conhecimento das Leis Anticorrupção a
              todos os profissionais que venham a ser relacionar com o Usuário,
              conforme aplicável; (iii)abstém – se de praticar quaisquer atos
              estabelecidos nas Leis Anticorrupção, no seu interesse ou para seu
              benefício, exclusivo ou não; e(iv) caso tenha conhecimento de
              qualquer ato ou fato que viole as Leis Anticorrupção, comunicará
              imediatamente a Quita Tecnologia, que poderá tomar todas as
              providências que entender necessárias.
            </Typography>
            <Typography className="descricao">
              7.10. O presente Termo de Uso será interpretado e regido conforme
              a legislação brasileira e aplicado em todo território nacional.
            </Typography>
            <Typography className="descricao">
              7.11. Este Termo de Uso rege a relação entre o Usuário e a Quita
              Tecnologia enquanto ativo o parcelamento ou pagamento contratado
              pelo Usuário ou seu cadastro no Portal Digital.
            </Typography>
            <Typography className="subtitulo">Foro </Typography>

            <Typography className="descricao">
              8.1. As partes elegem o foro da Cidade de Horizontina, Estado do
              Rio Grande do Sul, para dirimir eventuais dúvidas ou controvérsias
              decorrentes do presente Contrato, excluindo-se qualquer outro, por
              mais privilegiado que seja.
            </Typography>

            <Typography className="subtitulo">Taxas e Prazos </Typography>

            <Typography className="descricao">
              9.1. Nossas taxas de juros são variáveis e podem ser alteradas
              conforme for de desejo da Quita Tecnologia e o(s) boleto(s) ou PIX
              podem ser pagos em até 12 vezes.
            </Typography>

            <Typography className="titulo">
              Política de privacidade e segurança
            </Typography>
            <Typography className="descricao">
              A Política de Privacidade e Segurança descrita abaixo constitui
              parte das Condições de Termos e Uso do presente site, respeitando
              todas as normas previstas nas legislações vigentes em nosso país,
              principalmente na Lei Geral de Proteção de Dados. A Visita e
              permanência no site implica-se automaticamente na concordância
              explicita na política abaixo em todos os seus termos. Este site é
              operado, mantido e de inteira propriedade de Bruna Zinnau Ltda,
              inscrita no CNPJ nº 42.426.994/0001-52, com sede na Rua Santa
              Rosa, nº 167, Centro, Horizontina – RS, CEP 98920-000 e está
              hospedado com o endereço www.quitaboletos.com.br
            </Typography>
            <Typography className="subtitulo">Informações Gerais:</Typography>
            <Typography className="descricao">
              Este site realiza os tratamentos de dados pessoais de seus
              usuários, desde a fase da sua coleta até a sua eliminação,
              respeitando a legislação do país onde foi criado. Atuando como
              controlador dos dados pessoais coletados por meio de nossa
              plataforma, ficamos à disposição das normas trazidas na Lei
              Federal n. 13.709/2018, Lei Geral de Proteção de dados. O Site
              trabalha com todas as medidas necessárias para trazer segurança e
              proteção a privacidade de seus usuários, trazendo detalhadamente a
              forma de atuação da empresa referente as etapas de coleta,
              armazenamento, uso, compartilhamento e divulgação dos dados
              pessoais dos usuários, bem como a forma como é realizada a
              segurança e proteção dos dados que são coletados na nossa página.
              O site será tratado no decorrer dessa Política de Privacidade e
              Segurança como “nós”, “nos”, “conosco” e “nosso”.
            </Typography>
            <Typography className="subtitulo">Observação importante</Typography>
            <Typography className="descricao">
              O tratamento dos dados coletados por nossa plataforma respeita
              todas as normas estabelecidas na Lei Geral de Proteção de Dados,
              respeitando o direito do usuário de ter acesso aos seus dados e
              alterá-los a qualquer momento durante nosso armazenamento. Os
              dados tratados por nós estão todos seguros, pois utilizamos
              técnicas para preservar a privacidade de todos os titulares dos
              dados coletados. Não realizamos nenhum tratamento de dados ilícito
              ou que vá contra o que estabelece as normas brasileiras e esta
              Política de Privacidade e Segurança. As etapas dos tratamentos de
              dados iniciam-se desde a sua coleta. As atividades referente ao
              tratamento de dados realizadas por nós, tem base legal para
              acontecer, respeitando os limites previstos na legislação.
              Disponibilizamos o nosso canal de contato para responder qualquer
              dúvida a respeito da base legal que fundamentamos o tratamento dos
              dados. Você pode entrar em contato conosco pelos nossos canais de
              atendimento WhatsApp, Email, Telefone, carta endereçada.
              Realizamos o tratamento dos seus dados pessoais com total
              responsabilidade e para demonstrar a transparência da nossa coleta
              e utilização de dados pessoais, disponibilizamos a política de
              privacidade, que contém informações necessárias esclarecedoras
              para os usuários da nossa plataforma, como: • Nome do mantenedor,
              operador e proprietário de nossa página; • Para qual público é
              voltado nosso serviço; • Quais os dados são coletados; •
              Finalidade dos dados coletados? • Quem tem acesso aos dados
              coletados • Como ocorre o tratamento dos dados coletados? • Como
              protegemos seus Dados • Uso de cookies; • Dos seus Direitos •
              Formas de entrar em contato conosco.
            </Typography>
            <Typography className="subtitulo">
              SOBRE O NOSSA PLATAFORMA
            </Typography>
            <Typography className="descricao">
              Nosso site tem como principal objetivo informar e levar
              conhecimento sobre o nosso negócio, além de ser a plataforma
              através da qual realizamos as operações contratadas por nossos
              clientes, onde oferecemos a facilidade de parcelar o pagamento de
              seus débitos através de seus cartões de crédito. Esta política de
              privacidade e segurança tem como principal objetivo de esclarecer
              e demonstrar aos nossos clientes, como todas as informações, aqui
              coletadas, serão tratadas e quais poderão ser divulgadas ou
              divididas com nossos parceiros. Ao acessar o nosso site, o usuário
              concorda, aceita e adere de forma automática todas as informações
              e condições previstas neste documento, que pode ser denominado
              como Política de Privacidade e Segurança ou Termos de Uso, que
              devem ser obrigatoriamente respeitados. O site traz abaixo todas
              as regras e termos referente a privacidade e o uso da nossa
              plataforma, qualquer alteração efetuada na presente Política de
              Privacidade, se dará mediante informativos ou divulgada em nossa
              plataforma, sempre visando melhorá-la, adaptá-la ou atualizá-la em
              relação a eventuais alterações legislativas ou jurisprudenciais.
              Nossa plataforma não é destinada ao público infantil, portanto,
              não é esperado que menores de 16 anos de idade realizem cadastro
              junto a ela. Esclarecemos que não coletamos intencionalmente
              informações de menores de 18 (dezoito) anos de idade. Se os pais
              ou representantes legais acreditarem que seus filhos nos enviaram
              dados pessoais, poderão entrar em contato conosco enviando-nos um
              e-mail para contato@quitaboletos.com.br. O registro e subsequente
              navegação e uso de nossa plataforma depende da aceitação das
              previsões desta Política de Privacidade. Dessa forma, caso o
              Usuário não concorde com alguma das condições previstas, este
              poderá ficar impossibilitado de acessar os nossos serviços.
            </Typography>
            <Typography className="subtitulo">
              DA FORMA E DOS DADOS COLETADOS
            </Typography>
            <Typography className="descricao">
              1.1 Para possibilitar o melhor atendimento ao nosso cliente, A
              QUITA BOLETOS poderá coletar e armazenar os dados recolhidos no
              ato do cadastro e, posteriormente, no registro de informações
              adicionais oferecidos espontaneamente pelo próprio Usuário, tais
              como, mas não se limitando a: 1.1.1. Dados Cadastrais: São os
              dados pessoais solicitados para realização do seu cadastro em
              nossa plataforma, quais sejam: nome completo, e-mail, telefone,
              CPF, profissão, entre outros. Alguns dados opcionais poderão ser
              coletados para formação do seu perfil financeiro, que nos ajudarão
              a personalizar as funcionalidades da plataforma de acordo com seus
              interesses, além de te indicar parceiros que prestam serviços
              financeiros que poderão ser de seu interesse. 1.1.2. Dados do
              Cartão de Crédito: São os dados necessários para autorizar a sua
              transação, quais sejam: número do cartão, data de validade, código
              de segurança e Nome do titular. 1.1.3. Comunicações entre você e a
              QUITA BOLETOS: informações relativas a comunicações por e-mail,
              telefone, chat, chatbot, redes sociais e/ou messenger (whatsapp,
              telegram, entre outros). 1.2. Alguns dados poderão ser coletados e
              armazenados automaticamente, tais como, mas não se limitando a,
              características do dispositivo de acesso e do navegador, número de
              IP (Internet Protocol, número de identificação de dispositivo de
              rede) com informação de data e hora, origem do IP, funcionalidades
              acessadas, informações sobre cliques, geolocalização, entre
              outros. 1.3 Os dados coletados de forma automática são tratados
              com segurança e respeitando a privacidade de nossos usuários,
              sendo coletados diretamente pelo sistema. 1.4. Os dados poderão
              ser armazenados em servidores localizados no Brasil e/ou em
              território internacional. 1.5 O usuário do nossa plataforma ao
              acessa-la e preencher qualquer dos formulários do nosso site,
              assim como ao aceitar os cookies utilizados, demonstra de forma
              expressa e clara concorda e dá seu consentimento para a coleta e
              os demais tratamentos de seus dados. 1.6 Não realizamos nenhuma
              coleta de dados sem a permissão do usuário, ou por qualquer meio
              que possa tornar o consentimento inválido. 1.7 A coleta de dados
              pode acontecer através do preenchimento do Fale Conosco, de
              formulários presentes no nosso site, do preenchimento de cadastro
              no nosso site, de transações realizadas no nosso site ou do aceite
              para receber informações em seu e-mail ou número de contato.
            </Typography>

            <Typography className="subtitulo">
              {" "}
              FINALIDADE DA COLETA DE DADOS
            </Typography>
            <Typography className="descricao">
              2.1. Os seus dados pessoais poderão ser utilizados para os
              seguintes fins: a) Possibilitar a realização da transação
              contratada; b) aprimorar a experiência do site; c) emitir
              relatórios, gráficos e tabelas; d) criar alertas e eventos; e)
              enviar ou Usuário comunicações de caráter publicitário relativas à
              QUITA BOLETOS ou de nossos parceiros, bem como sobre a prestação
              de nossos serviços, através de sms, whatsapp ou e-mail; f)
              realizar atividades comerciais, operativas e estatísticas; g)
              enviar conteúdo por e-mail, newsletter e outras comunicações
              relacionadas com a QUITA BOLETOS, sendo possibilitado ao Usuário,
              a qualquer tempo, manifestar sua oposição desativando seus envios;
              h) realização de análises comerciais e construção de jornada de
              experiência dos atuais e potenciais Usuários; i) prestar
              atendimento quando você precisar; j) pesquisa e recomendação de
              serviços financeiros que se encaixem no seu perfil.
            </Typography>

            <Typography className="subtitulo">
              QUEM TEM ACESSO AOS DADOS?
            </Typography>
            <Typography className="descricao">
              3.1. Poderemos compartilhar seus dados com alguns de nossos
              parceiros comerciais para recomendação ou oferta de produtos e
              serviços que melhor se encaixem com seu perfil. Nossos parceiros
              apenas poderão utilizar os dados pessoais para os fins específicos
              para os quais foram contratados. Isso significa que eles não
              poderão compartilhar seus dados com terceiros ou utilizá-los para
              outros fins, sempre em atendimento às legislações de privacidade e
              proteção de dados aplicáveis. 3.2. Os dados pessoais poderão ser
              compartilhados para cumprir obrigação legal, contratual ou
              administrativa, aperfeiçoar os produtos ou serviços ofertados pela
              QUITA BOLETOS ou em caso de contarmos com parceiros externos que
              nos prestem serviços de Alojamento WEB ou manutenção. 3.3.5. Os
              dados do Usuário poderão, ainda, ser repassados a terceiros de
              modo oneroso ou não, em formato de dado estatístico e anônimo, sem
              informações individualizadas, e utilizados para fins
              publicitários, garantindo-se que não será possível a identificação
              do Usuário por meio do anúncio.
            </Typography>
            <Typography className="subtitulo">
              POR QUANTO TEMPO MANTEREMOS SEUS DADOS?
            </Typography>
            <Typography className="descricao">
              4.1. Os dados serão mantidos em nosso banco de dados durante todo
              o período em que você permanecer cadastrado em nossa plataforma e,
              posteriormente a isso, apenas dentro do prazo legal estabelecido.
            </Typography>

            <Typography className="subtitulo">
              COMO PROTEGEMOS SEUS DADOS?
            </Typography>
            <Typography className="descricao">
              5.1. A QUITA BOLETOS informa que todos os dados pessoais de
              usuários são armazenados de forma criptografada, além de serem
              sincronizadas, processadas e armazenados em servidores com alto
              padrão de proteção, garantindo segurança a nível bancário a todos
              os dados de nossa base. 5.2. As medidas de segurança tomadas
              buscam inibir e prevenir problemas que envolvam o tratamento dos
              dados fornecidos, ainda que não exista sistema 100% (cem por
              cento) seguro de proteção contra atuações de hackers ou terceiros
              munidos de métodos de obtenção de informações de forma indevida.
              Em razão disso, a QUITA BOLETOS não se responsabiliza por acessos
              ilícitos, bem como por atos de terceiros que logrem êxito em
              coletar ou utilizar, por quaisquer meios, dados cadastrais e
              informações disponibilizadas na plataforma pelo Usuário.
            </Typography>

            <Typography className="subtitulo">COOKIES</Typography>
            <Typography className="descricao">
              6.1. Os Cookies são arquivos criados pelos websites frequentados
              que registram as informações de navegação, possibilitando que o
              Usuário se mantenha conectado, ao mesmo tempo que lembra suas
              preferências do site e fornece conteúdo direcionados. 6.2. A QUITA
              BOLETOS poderá utilizar cookies para coletar informações do
              Usuário e melhorar a experiência de navegação. Entretanto, caso o
              Usuário queira rejeitar a instalação desses cookies, poderá
              bloqueá-los ou eliminá-los nas próprias preferências do navegador.
              6.3 Utilizamos os cookies para monitorar o uso do nosso site pelos
              usuários, assim poderemos observar métricas e, obtendo informações
              a respeito do tempo que você permanece em nossa página. 6.4 Não
              recomendamos que você recuse ou desative os cookies, pois é por
              meio deles que lhe proporcionamos uma experiência melhor e
              direcionada as suas necessidades, além disso a recusa ou
              desativação diminui as funcionalidades disponíveis para você, como
              por exemplo o preenchimento de formulários para que possamos
              retornar algum contato que você realize.
            </Typography>

            <Typography className="subtitulo">DOS SEUS DIREITOS</Typography>
            <Typography className="descricao">
              7.1.1. A Lei Geral de Proteção de Dados Pessoais (Lei nº
              13.709/2018), garante a você determinados direitos e garantias em
              relação aos seus dados pessoais. A MOBILLS, cumprindo com a sua
              obrigação de clareza e transparência, além de detalhar nesta
              política como utiliza e protege seus dados pessoais,
              disponibiliza, neste link, um canal gratuito e facilitado para que
              você possa exercer seus direitos de: 7.1.1.1. Solicitar
              Informações sobre o tratamento de seus dados pessoais: Você pode
              confirmar se realizamos o tratamento de seus dados pessoais,
              requerer o acesso aos mesmos e correção de dados incorretos;
              solicitar a relação de todos os agentes com quem compartilhamos
              seus dados; 7.1.1.2. Portabilidade: Você tem o direito de
              solicitar a migração das suas informações pessoais para outra
              organização; 8.1.1.3. Solicitar a exclusão ou anonimização de
              dados pessoais: Você tem o direito de nos pedir a anonimização ou
              eliminação definitiva dos seus dados, caso eles se mostrem
              desnecessários ou excessivos para a finalidade do tratamento, ou
              caso estejam sendo tratados em desconformidade com a LGPD;
              7.1.1.4. Revogação do consentimento: Você tem o direito de saber
              as consequências de recusar o fornecimento de consentimento para
              tratamento de suas informações pessoais e requerer a revogação do
              mesmo. 7.1.2. Caso você solicite a exclusão de todos os dados
              incluídos em nossa plataforma, por meio do e-mail ou do site, isto
              implicará na impossibilidade de acesso a qualquer das
              funcionalidades desta, assim como sua posterior recuperação,
              exceto aqueles cujo armazenamento é autorizado pela lei. 7.1.3.
              Você também poderá entrar em contato com nosso encarregado de
              dados através do e-mail bruno@quitaboletos.com.br.
            </Typography>

            <Typography className="subtitulo">
              FORMAS DE ENTRAR EM CONTATO CONOSCO
            </Typography>
            <Typography className="descricao">
              8.1 Buscamos sempre o melhor relacionamento com o nosso usuário,
              por esse motivo estamos a disposição para responder qualquer
              dúvida a respeito da nossa Política de Privacidade e segurança e a
              informar sobre o tratamento de dados realizados por nós. 8.2 Você
              pode entrar em contato conosco através de e-mail, telefone ou
              endereço disposto na aba Quem somos, ou Contato. Está Política de
              Privacidade e Segurança passa a ter validade a partir de 17 de
              fevereiro de 2022, vigendo por prazo indeterminado.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className="contato"
          style={{ marginTop: "3rem", cursor: "pointer" }}
          onClick={() => {
            window.open(
              "https://api.whatsapp.com/send?phone=5555999042235&text=Ol%C3%A1. Preciso de ajuda com o portal!"
            );
          }}>
          <Grid item size={12}>
            <Typography align="center" className="contato-texto">
              Ficou com dúvidas?
            </Typography>{" "}
            <Typography align="center" className="contato-texto">
              Entre em contato conosco:
            </Typography>
          </Grid>
          <Grid item size={12} align="center">
            <img src="/whatsapp.png" alt=""></img>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default TermosUso;

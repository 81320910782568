import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Typography } from "@mui/material";
import BotaoSimular from "@/components/BotaoSimular";
import Grid from "@mui/material/Grid2";

export default function PagarAsContasSemComprometerTodoOrcamento() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_PAGAR_AS_CONTAS_SEM_COMPROMETER_TODO_ORCAMENTO",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          size={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              size={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog_pagar_contas_sem_comprometer_todo_seu_orcamento_de_uma_vez.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid size={12}>
              <Typography
                variant="h1"
                sx={{ mt: 2, p: 1, textAlign: "center" }}>
                Pagar contas: sem comprometer todo seu orçamento de uma vez{" "}
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 22, fontStyle: "italic" }}>
                Manter as contas em dia é um desafio para muitos brasileiros,
                especialmente quando imprevistos surgem e o orçamento fica
                apertado. Mas sabia que existem formas de manter as finanças
                organizadas sem comprometer todo o seu dinheiro de uma vez?
                Vamos entender como uma boa gestão financeira e soluções
                inteligentes podem ajudar nessa missão.
              </Typography>
            </Grid>

            <Grid container spacing={2} sx={{ mx: 1, mt: 2 }}>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  O Impacto do acúmulo de contas na nossa rotina e saúde mental{" "}
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  A falta de controle financeiro pode gerar ansiedade e
                  estresse. Contas atrasadas acumulam juros, criando um efeito
                  bola de neve que dificulta ainda mais o pagamento. Essa
                  pressão afeta o bem-estar emocional, impacta a produtividade e
                  pode até prejudicar o convívio social e familiar. Ter um
                  planejamento adequado é essencial para evitar essa situação e
                  garantir uma relação mais saudável com o dinheiro.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  Educação Financeira: O caminho para manter as contas em dia
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Entender para onde vai o dinheiro é o primeiro passo para um
                  orçamento equilibrado. Separar gastos fixos e variáveis,
                  definir prioridades e evitar compras impulsivas são
                  estratégias essenciais. Pequenas mudanças nos hábitos diários
                  já fazem diferença, como anotar os gastos, revisar as faturas
                  e negociar condições de pagamento antes que uma conta vire
                  dívida.
                </Typography>
                <Typography sx={{ fontSize: 20, mt: 2 }}>
                  Mesmo com planejamento, nem sempre é possível prever todas as
                  despesas. Problemas de saúde, desemprego ou consertos
                  inesperados podem comprometer o orçamento mensal. Ter uma
                  reserva de emergência é a solução ideal, mas quando isso não é
                  possível, é fundamental buscar alternativas que evitem o
                  endividamento excessivo.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  Flexibilidade e controle para evitar juros abusivos{" "}
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Recorrer ao cheque especial ou ao rotativo do cartão de
                  crédito pode ser uma armadilha perigosa, pois esses são os
                  tipos de crédito com os juros mais altos do mercado. Opções
                  como o parcelamento de contas são uma solução mais vantajosa
                  para evitar encargos excessivos e manter o controle financeiro
                  sem comprometer a renda mensal.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  Conheça o parcelamento de contas da{" "}
                  <span className="quita">Quita Boletos</span>
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Se você precisa pagar contas, mas não quer comprometer todo o
                  seu orçamento de uma vez, a{" "}
                  <span className="quita">Quita Boletos</span> pode te ajudar.
                  Com a nossa solução, você pode parcelar boletos e Pix em até
                  12x no cartão de crédito, permitindo que os pagamentos sejam
                  feitos de forma mais suave, sem pesar no bolso. Assim, você
                  evita atrasos, juros abusivos e ganha mais fôlego para
                  organizar as suas finanças.
                </Typography>
                <Typography sx={{ fontSize: 20, mt: 2 }}>
                  Não deixe que as contas se tornem uma dor de cabeça! Conte com
                  a <span className="quita">Quita Boletos</span> para ter mais
                  tranquilidade e segurança financeira. Acesse nosso simulador e
                  descubra como parcelar suas contas de forma rápida e simples.
                </Typography>
              </Grid>

              <Grid size={12} align="center">
                <BotaoSimular />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import BotaoSimular from "@/components/BotaoSimular";
import Grid from "@mui/material/Grid2";
import { Container, Typography } from "@mui/material";

export default function BlogSolucoesFinanceirasParaNegativados() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_SOLUCOES_FINANCEIRAS_PARA_NEGATIVADOS",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          size={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              size={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog_solucoes_financeiras_para_negativados.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid size={12}>
              <Typography
                variant="h1"
                sx={{ mt: 2, p: 1, textAlign: "center" }}>
                Soluções financeiras para negativados: Oportunidades e desafios
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 22, fontStyle: "italic" }}>
                Em um cenário onde muitas pessoas enfrentam dificuldades
                financeiras, as soluções financeiras para negativados se
                tornaram uma opção cada vez mais procurada. Essa modalidade de
                crédito oferece alternativas para quem está com restrições no
                CPF e busca uma maneira de reorganizar suas finanças.
              </Typography>
            </Grid>

            <Grid container spacing={2} sx={{ mx: 1, mt: 2 }}>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  O que são soluções financeiras para negativados?{" "}
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  As soluções financeiras para negativados são alternativas de
                  crédito destinadas a indivíduos com nome sujo nos órgãos de
                  proteção ao crédito, como SPC e Serasa. Essa modalidade pode
                  ser especialmente útil em momentos de emergência financeira ou
                  quando há necessidade urgente de dinheiro para quitar dívidas.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  Quais são as opções disponíveis?{" "}
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Diversas instituições financeiras oferecem soluções
                  específicas para negativados, com foco em servidores públicos,
                  aposentados e pensionistas. Outra opção viável são as
                  plataformas de pagamento que permitem o parcelamento de
                  boletos e contas em até 12 vezes no cartão de crédito,
                  proporcionando mais flexibilidade financeira.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  Principais desafios das soluções financeiras para negativados:
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Apesar das oportunidades, os juros dessas soluções tendem a
                  ser mais altos do que os oferecidos aos clientes com bom
                  histórico de crédito. Isso pode aumentar o custo total do
                  empréstimo e dificultar o pagamento das parcelas. É essencial
                  avaliar as taxas de juros, condições de pagamento e o impacto
                  financeiro antes de contratar qualquer serviço.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  Novas tendências no mercado financeiro{" "}
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Recentemente, surgiram opções inovadoras, como o uso da
                  tecnologia Pix para facilitar os pagamentos. O mercado também
                  tem apostado em serviços digitais que permitem a contratação
                  de crédito sem burocracia, ampliando as opções para quem
                  precisa regularizar sua situação financeira rapidamente.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  Parcelamento de Pix e boletos: Uma alternativa acessível{" "}
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Uma alternativa interessante é a opção de parcelamento da
                  <span className="quita"> Quita Boletos</span>, que permite
                  dividir contas em até 12x no cartão de crédito. Essa solução é
                  ideal para quem precisa organizar suas finanças sem
                  comprometer o orçamento mensal, tornando o pagamento de
                  dívidas mais acessível e flexível.
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  E o melhor: a solução{" "}
                  <span className="quita">Quita Boletos</span> não consulta o
                  histórico de crédito, ou seja, os juros não variam conforme o
                  score ou qualquer outra informação financeira. A solução está
                  disponível para qualquer pessoa com um cartão de crédito e
                  limite disponível, permitindo o parcelamento de PIX e boletos
                  sem consulta ao SPC ou Serasa.
                </Typography>
                <Typography sx={{ fontSize: 20, fontStyle: "italic" }}>
                  As soluções financeiras para negativados podem ser uma
                  alternativa eficiente para reorganizar as finanças. No
                  entanto, antes de contratar qualquer serviço, é fundamental
                  comparar opções, analisar taxas e conferir as condições de
                  pagamento para evitar problemas no futuro.
                </Typography>
                <Typography sx={{ fontSize: 20, fontStyle: "italic" }}>
                  Com a <span className="quita">Quita Boletos</span>, parcelar é
                  simples: basta acessar o simulador, informar os dados do
                  parcelamento e concluir tudo de forma 100% online, sem
                  consulta ao CPF e sem burocracia.
                </Typography>
              </Grid>

              <Grid size={12} align="center">
                <BotaoSimular />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

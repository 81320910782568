import { useEffect } from "react";
import Grid from "@mui/material/Grid2";
export default function SeloReclameAqui() {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "ra-embed-verified-seal";
    script.src = "https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js";
    script.dataset.id = "RmNnY2lQZHFSLVo0b0RNcTpxdWl0YS1ib2xldG9z";
    script.dataset.target = "ra-verified-seal";
    script.dataset.model = "2";

    const container = document.getElementById("ra-verified-seal");
    if (container) {
      container.appendChild(script);
    }

    return () => {
      if (container) {
        container.removeChild(script);
      }
    };
  }, []);

  return <Grid id="ra-verified-seal" sx={{ mt: 3 }}></Grid>;
}
